<script>
import {Line, Bar, mixins} from 'vue-chartjs'

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: ['data', 'options', 'plugins'],
  data: function () {
    return {
      opts: {
        responsive: true,
        maintainAspectRatio: false,

        interaction: {
          mode: 'index',
          intersect: false,
        },
        stacked: false,

        parsing: {
          xAxisKey: 'x',
          yAxisKey: 'y',
        },

        scales: {
          x: {
            // The axis for this scale is determined from the first letter of the id as `'x'`
            // It is recommended to specify `position` and / or `axis` explicitly.
            display: true,
            title: {
              display: true,
              text: 'Date'
            },
          },
          xAxes: [
            {
              type: 'linear',
              barThickness: 'flex',  // number (pixels) or 'flex'
              categorySpacing: 0,
              title: 'Цена товара, руб',

              ticks: {
                maxTicksLimit: 10,
              },

            }
          ],
          yAxes: [
            {
              id: 'A',
              type: 'linear',
              position: 'left',
              grid: {
                drawOnChartArea: false, // only want the grid lines for one axis to show up
              },
            },
            {
              id: 'B',
              type: 'linear',
              position: 'right',
              grid: {
                drawOnChartArea: false, // only want the grid lines for one axis to show up
              },
            },
          ]
        },


      }
    }
  },
  mounted() {
  },
  methods: {
    rend: function () {
      this.renderChart(this.chartData, this.opts);
    }
  }
}
</script>

<style>
</style>
