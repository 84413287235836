<template lang="pug">
  div(style="position: relative")

    CCard
      CCardBody
        CRow
          CCol(sm=5)
            div
              h4(class="card-title mb-0") {{item.name}}
            div
              slot(name="desc")
                img(:src="'/img/services/' + item.service + '.ico'")
                span(class="")  {{item.path}}
    div.loader
      div.loader-overlay(v-if="isLoading")
        div(style="margin: 0 auto")
          div.lds-dual-ring
      CRow
        CCol
          CButton(:color="'info'", v-bind="period.button===0 ? { shape: 'square' } : { variant: 'outline' }", class="m-2", active, @click="setPeriod(0)") За всё время
          CButton(:color="'info'", v-bind="period.button===7 ? { shape: 'square' } : { variant: 'outline' }", class="m-2", active, @click="setPeriod(7)") 7 дней
          CButton(:color="'info'", v-bind="period.button===14 ? { shape: 'square' } : { variant: 'outline' }", class="m-2", active, @click="setPeriod(14)") 14 дней
          CButton(:color="'info'", v-bind="period.button===30 ? { shape: 'square' } : { variant: 'outline' }", class="m-2", active, @click="setPeriod(30)") 30 дней


      CCard
        CCardBody
          // Верхняя стата
          CRow(style="margin-top: 15px; margin-left: auto")
            CCol(sm="4", lg="3")
              CWidgetSimple(header="Выручка" :text="format(Stats.oborot) + ' руб'")
            CCol(sm="4", lg="3")
              CWidgetSimple(header="Продаж" :text="format(Stats.sales)")
            CCol(sm="4", lg="3")
              CWidgetSimple(header="Средний чек" :text="(Stats.oborot/Stats.sales > 0 ? format(Stats.oborot/Stats.sales) : 0) + ' руб'")
            CCol(sm="4", lg="3")
              CWidgetSimple(header="Остатки" :text="format(Stats.totalQt)")

      // Графики
      CCard
        CCardBody
          CRow(style="margin-top: 55px;")

            // График Продаж vs Цена vs Скидка
            CCol(sm="6")
              CCard
                CCardHeader
                  h4 Состояние склада
                CCardBody
                  //CChartBar(:datasets="graphs.PriceSellsDataset.datasets", :options="graphs.opts")
                  LineChart(ref="Graph1", :chartData="graphs.Graph1" )


            CCol(sm="6")
              CCard
                CCardHeader
                  h4 Динамика продаж
                CCardBody
                  //CChartBar(:datasets="graphs.PriceSellsDataset.datasets", :options="graphs.opts")
                  LineChart(ref="Graph2", :chartData="graphs.Graph2" )

      // Графики
      CCard
        CCardBody
          CRow(style="margin-top: 55px;")

            // График Продаж vs Цена vs Скидка
            CCol(sm="12")
              CCard
                CCardHeader
                  h4 Зависимость продаж от цены на товар
                CCardBody
                  //CChartBar(:datasets="graphs.Graph3.datasets")
                  LineChart2(ref="Graph3", :chartData="graphs.Graph3" )



    // Таблица с товарами
    CCard(ref="goodsTable")
      CCardBody
        CRow(style="margin-top: 15px; margin-left: auto")
          CCol
            h4 {{request.goodsTitle}} {{item.name}}
            GoodsTable(:opts="opts", style="margin-top:15px")

</template>

<script>

import LineChart from "@/components/BarChart";
import LineChart2 from "@/components/LineChart";
import GoodsTable from "@/views/App/base/GoodsTable";

export default {

  name: 'CategoryBase',

  props: ['requestopts'],

  components: {GoodsTable, LineChart,LineChart2},

  data: function () {
    let that = this;
    return {

      request: {
        table: 'categories',
        graphs: 'category',
        goodsTitle: 'Товары в категории',
        goodsWhere: {
          categories: that.$route.params.Id
        }
      },

      item: {
        Id: that.$route.params.Id,
        orders: 0,
        price: 0,
        quantity: 0,
        name: "",
        service: 0
      },

      Stats: {
        oborot: 0, // Точно подсчитанный оборот
        sales: 0, // Всего заказов
        totalQt: 0 // Остатки
      },

      graphs: {
        Graph1: {datasets: []},
        Graph2: {datasets: []},
        Graph3: {datasets: []},
      },

      period: {
        from: 0,
        to: 0,
        button: 0
      },

      show: true,
      isCollapsed: true,

      isLoading: true,

      opts: {
        canAdd: false,
        canEdit: false,
        canDelete: false,
        where: {
          categories: that.$route.params.Id
        }
      }

    }
  },
  mounted() {

    this.$set(this, 'request', Object.assign(this.request, this.requestopts))
    this.$set(this.opts, 'where', this.requestopts.goodsWhere)

    this.update();

  },
  methods: {

    setPeriod: function (period, periodFrom, periodTo) {

      this.period.button = period;

      if (period !== 0) {
        let d = (new Date())
        d.setDate(d.getDate() - period);
        this.period.from = d.toISOString().slice(0, 19).replace('T', ' ');
        this.period.to = (new Date()).toISOString().slice(0, 19).replace('T', ' ');
      } else {
        //TODO: периодфром и ту
        this.period.from = 0;
        this.period.to = 0;
      }

      // Делаем запрос с новыми данными
      this.update();

    },

    update: function () {

      let that = this;

      that.isLoading = true;

      let periodsstr = '';
      if (that.period.to !== 0) periodsstr += '&periodTo=' + that.period.to
      if (that.period.from !== 0) periodsstr += '&periodFrom=' + that.period.from

      // сперва грузим сам товар
      window.REST.get(that.request.table, {sourceID: this.$route.params.Id}).then(res=>{
       // that.$set(that, 'item', res.data[0]);
        that.item = res.data[0];
        that.item.quantity = 0;
        that.$set(this.$route.meta,'label', that.item.name);
        that.$store.commit('routeThis', this);
      }).then(()=> {

        // ГРАФИКИ
        window.REST.get('goods/graphs?priceordersgraph&' + that.request.graphs + '=' + that.item.sourceID + periodsstr).then(res => {

          that.$set(that.Stats, 'oborot', res.data.Stats.oborot);
          that.Stats.sales = res.data.Stats.sales;
          that.Stats.totalQt = res.data.GoodsStats.totalqt;


          that.graphs.Graph1.datasets = [
            {
              data: res.data.PriceSells.map(r => {
                return {x: new Date(parseInt(r.y), parseInt(r.m) - 1, parseInt(r.d)), y: r.totalsoldlastday};
              }),
              backgroundColor: '#E55353',
              label: 'Продажи за день',
              yAxisID: 'A',
              type: 'line',
              fill: false,
              color: 'blue',
              borderColor: 'blue'
            },
            {
              data: res.data.PriceSells.map(r => {
                return {x: new Date(parseInt(r.y), parseInt(r.m) - 1, parseInt(r.d)), y: r.priceavg};
              }),
              backgroundColor: '#CCC',
              label: 'Цена',
              yAxisID: 'B',
              type: 'bar',
            },
          ];

          that.graphs.Graph2.datasets = [
            {
              data: res.data.PriceSells.map(r => {
                return {x: new Date(parseInt(r.y), parseInt(r.m) - 1, parseInt(r.d)), y: r.totalsoldlastday};
              }),
              backgroundColor: '#E55353',
              label: 'Продажи за день',
              yAxisID: 'A',
              type: 'line',
              fill: false,
              color: 'blue',
              borderColor: 'blue'
            },
            {
              data: res.data.PriceSells.map(r => {
                return {x: new Date(parseInt(r.y), parseInt(r.m) - 1, parseInt(r.d)), y: r.quantitysum};
              }),
              backgroundColor: '#CCC',
              label: 'Остатки',
              yAxisID: 'B',
              type: 'bar',
            },
          ];

          //res.data.PriceOrders.length = 20;
          that.graphs.Graph3.datasets = [
            {
              data: res.data.PriceOrders.filter(r => r.orders > 0).map(r => {
                return {x: parseInt(r.price), y: parseInt(r.orders)};
              }),
              backgroundColor: '#E55353',
              label: 'Продажи за весь период',
            },
          ];


          that.$refs.Graph1.rend();
          that.$refs.Graph2.rend();
          that.$refs.Graph3.rend();

          that.isLoading = false;

          if (this.$route.query.scroll !== undefined) {
            setTimeout(() => this.scrollMeTo(this.$route.query.scroll), 100);
          }

        })

      })
    },

    columnsupdated: function (cols) {

      cols.set('source_id', {label: "SKU", hasFilter: true, width: '300px'});
      cols.set('brand', {label: "Бренд", hasFilter: true,});
      cols.set('name', {label: "Название", hasFilter: true,});
      cols.set('rating', {label: "Позиция", hasFilter: true,});
      cols.set('color', {label: "Цвет", hasFilter: false,});
      cols.set('orders', {label: "Продаж", hasFilter: true,});
      cols.set('price', {label: "Цена", hasFilter: true,});
      cols.set('quantity', {label: "Остатки", hasFilter: true,});
      cols.set('reviews', {label: "Отзывы", hasFilter: true,});
      cols.set('stars', {label: "Оценка", hasFilter: true,});
      cols.set('sellername', {label: "Продавец", hasFilter: true,});
      cols.set('_totalsumm', {label: "Оборот", hasFilter: true,});


      // Ненужные колонки
      cols.delete('Id');
      cols.delete('service');
      cols.delete('categories');
      cols.delete('brandid');
      cols.delete('pricefull');
      cols.delete('discount');
      cols.delete('firstreview');
      cols.delete('sellerid');
      cols.delete('imglink');
      cols.delete('changes');
      cols.delete('_avgpos');
      cols.delete('_avgPrice');
      cols.delete('_stockdays');
      cols.delete('_saledays');
      cols.delete('_salesumm');
      cols.delete('_counteddays');

    },
    gotoItem(row) {
      this.$router.push({name: 'Good', params: {Id: row.Id}});
    },

    format(number) {
      return (new Intl.NumberFormat('ru-RU').format(Math.round(number))).toString();
    },

    scrollMeTo(refName) {
      let top = this.$refs[refName].offsetTop;
      window.scrollTo(0, top);
    }

  }
}
</script>

<style lang="scss">
.loader {

  position: relative;

}

.loader-overlay {
  opacity: 0.9;
  background-color: white;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid black;
  border-color: gray transparent gray transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

</style>