<template lang="pug">

  vuecrud(selectedtable = "goods", :columnsupdated="columnsupdated", :opts="cOpts", style="margin-top:15px")
    template(v-slot:row="binds")
      tr.goodsTable
        td(width="120px")
          div.tflex
            img(:src="'/img/services/' + binds.row.service + '.ico'")
            span(style="margin-left: 3px")
              a(target="blank", :href="binds.row.service == 1 ? 'https://www.wildberries.ru/catalog/'+binds.row.source_id+'/detail.aspx' : 'https://www.ozon.ru/context/detail/id/'+binds.row.source_id+'/'") {{binds.row.source_id}}
        td
          div.tflex
            div
              img(:src="constructHostV2(binds.row.source_id)", style="max-height:36px")
            div(style="margin-left: 4px; text-align: left;")
              span {{binds.row.name}}
        td {{binds.row.brand}}
        td {{binds.row.sellerName}}
        td {{binds.row.color}}
        td(width="100px") {{format(binds.row.quantity)}}
        td(width="100px")
          div.mainprice {{format(binds.row.price)}} руб
          div.discount (-{{binds.row.discount}}%)
          div.fullprice {{format(binds.row.priceFull)}} руб
        td(width="100px") {{format(binds.row.reviews)}}
        td(width="90px") {{binds.row.stars}}
        //td {{binds.row.lastParsed}}

</template>

<script>
export default {
  name: "GoodsTable",
  props: ['opts'],
  methods: {
    columnsupdated: function (cols) {

      cols.set('source_id', {label: "SKU", hasFilter: true, type: "fixed"});
      cols.delete('service', {label: "МП", hasFilter: true, type: "fixed"});
      cols.set('brand', {label: "Бренд", hasFilter: true, type: "fulltext", filterMinSymbolsRequest: 2, });
      cols.set('sellerName', {label: "Продавец", hasFilter: true, type: "fulltext", filterMinSymbolsRequest: 2, });
      cols.set('name', {label: "Название", hasFilter: true, type: "fulltext", filterMinSymbolsRequest: 2, });
      cols.delete('rating', {label: "Позиция", hasFilter: true,});
      cols.set('color', {label: "Цвет", hasFilter: false,});
      cols.set('orders', {label: "Продаж", hasFilter: true, filterRow: 1});
      cols.set('price', {label: "Цена", hasFilter: true, filterRow: 1});
      cols.delete('quantity', {label: "Остатки", hasFilter: true, filterRow: 1});
      cols.set('reviews', {label: "Отзывы", hasFilter: true, filterRow: 1});
      cols.set('stars', {label: "Оценка", hasFilter: true, filterRow: 1});
      cols.delete('_totalSumm', {label: "Оборот", hasFilter: true, filterRow: 1});
      cols.delete('lastParsed', {label: "Обновлено", hasFilter: false,  filterRow: 2});
      //cols.set('imgLink', {label: "Фото", hasFilter: false, });


      // Ненужные колонки
      cols.delete('id');
      //cols.delete('sourceID');
      cols.delete('categories', {hasFilter: true});
      cols.delete('brandID');
      cols.delete('priceFull');
      cols.delete('discount');
      cols.delete('firstReview');
      cols.delete('sellerID');
      cols.delete('imgLink');
      cols.delete('changes');
      cols.delete('_avgPos');
      cols.delete('_avgPrice');
      cols.delete('_stockDays');
      cols.delete('_saleDays');
      cols.delete('_saleSumm');
      cols.delete('_countedDays');

    },

    volHostV2: function (t) {
      return t >= 0 && t <= 143 ? "//basket-01.wb.ru/" : t >= 144 && t <= 287 ? "//basket-02.wb.ru/" : t >= 288 && t <= 431 ? "//basket-03.wb.ru/" : t >= 432 && t <= 719 ? "//basket-04.wb.ru/" : t >= 720 && t <= 1007 ? "//basket-05.wb.ru/" : t >= 1008 && t <= 1061 ? "//basket-06.wb.ru/" : t >= 1062 && t <= 1115 ? "//basket-07.wb.ru/" : t >= 1116 && t <= 1169 ? "//basket-08.wb.ru/" : t >= 1170 && t <= 1313 ? "//basket-09.wb.ru/" : t >= 1314 && t <= 1601 ? "//basket-10.wb.ru/" : t >= 1602 && t <= 1655 ? "//basket-11.wb.ru/" : t >= 1656 && t <= 1919 ? "//basket-12.wb.ru/" : "//basket-13.wb.ru/"
    },

    constructHostV2: function (t) {
      let e = parseInt(t, 10)
          , n = ~~(e / 1e5)
          , r = ~~(e / 1e3)
          , o = this.volHostV2(n);
      return "https:".concat(o, "vol").concat(n, "/part").concat(r, "/").concat(t, "/images/big/1.jpg")
    },

    gotoItem(row) {
      this.$router.push({name: 'Good', params:{id: row.id}});
    },

    format(number) {
      return (new Intl.NumberFormat('ru-RU').format(Math.round(number))).toString();
    }


  },

  computed: {
    cOpts: function () {
      return Object.assign({
        filterRowMargin: 10,
        canAdd: false,
        canEdit: false,
        canDelete: false
      }, this.opts)
    }
  }
}
</script>

<style lang="scss">

.goodsTable {

  td {
    text-align: left;
    vertical-align: middle;
  }

  .tflex {
    display: flex;
    flex-direction: row;
    * {
      margin: auto 0;
    }
  }
  .value {
    color: blue;
    cursor: pointer;
  }
  .value:hover {
    text-decoration: underline;
  }

  .mainprice {
    color: black;
  }
  .discount {
    color: red;
    font-size: 11px;
  }
  .fullprice {
    text-decoration: line-through;
    font-size: 11px;
  }

}

</style>